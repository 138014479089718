
































import { UserModule } from '@/store/modules';
import { Component, Vue } from 'vue-property-decorator'

enum GMPanelItemCategoryEnum {
  GAME = 'Game',
  LOGS = 'Logs',
  MANAGEMENT = 'Management'
}

@Component({
  components: {
    GMPanelAppCard: () => import('@/components/GMPanelAppCard.vue'),
    SearchCharacterByIdModal: () => import('@/components/Character/SearchCharacterByIDModal.vue'),
    RefundItemModal: () => import('@/components/RefundItemModal.vue')
  },
})
export default class GMPanel extends Vue {
  @UserModule.State currentUser

  get isSGM() {
    return this.currentUser.isRoot
        || this.currentUser.isAdmin
        || this.currentUser.displayAuthority === 'SuperGameMaster'
  }

  get GMItems(): GMPanelItem[] {
    return [
      {
        name: 'Character Viewer',
        description: 'View a character',
        category: GMPanelItemCategoryEnum.GAME,
        action: () => this.$root.$emit('openCharacterViewerSearchModal')
      },
      {
        name: 'Character Transfer',
        description: 'Transfer a character',
        category: GMPanelItemCategoryEnum.GAME,
        action: () => this.$router.push('/gm/character-transfer'),
        isSGM: true
      },
      {
        name: 'Shell Parser',
        description: 'An easier way to view shells',
        category: GMPanelItemCategoryEnum.GAME,
        action: () => this.$router.push('/gm/shell-parser')
      },
      {
        name: 'Item Creation',
        description: 'Refund an item from logs',
        category: GMPanelItemCategoryEnum.GAME,
        action: () => this.$root.$emit('openRefundItemModal')
      },
      {
        name: 'Nickname changes',
        description: 'Check nickname changes',
        category: GMPanelItemCategoryEnum.GAME,
        action: () => this.$router.push('/gm/nickname-changes'),
      },
      {
        name: 'Character Logs',
        description: 'Show character logs',
        category: GMPanelItemCategoryEnum.LOGS,
        action: () => this.$router.push('/gm/logs/character'),
      },
      {
        name: 'Bid Information',
        description: 'Show bid information',
        category: GMPanelItemCategoryEnum.LOGS,
        action: () => this.$router.push('/gm/logs/bids'),
      },
      {
        name: 'Punishments Logs',
        description: 'Show punishments logs',
        category: GMPanelItemCategoryEnum.LOGS,
        action: () => this.$router.push('/gm/logs/punishments')
      },
      {
        name: 'Find Alts',
        description: 'Display technical stuff and alts characters of a player',
        category: GMPanelItemCategoryEnum.MANAGEMENT,
        action: () => this.$router.push('/gm/find-alts')
      },
      {
        name: 'Bulk User Ban',
        description: 'Ban multiple users',
        category: GMPanelItemCategoryEnum.MANAGEMENT,
        action: () => this.$router.push('/gm/bulk-user-ban'),
      },
    ].filter(e => this.isSGM ? e : !e.isSGM)
  }
  GMCategories = Object.keys(GMPanelItemCategoryEnum).map(key => GMPanelItemCategoryEnum[key])
  characterId = null
}
